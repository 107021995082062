import request from '@/router/axios';
import {businessUrl} from "@/config/env"


//查询车间
export const getWorkShop = () => {
    return request({
        url: businessUrl + "department/selectWorkShopName?type=1",
        method: "get",
        params: {
            type: 1
        }
    })
}


//查询工序
export const getProcess = (departmentId) => {
    return request({
        url: businessUrl + "process/selectByWorkShopId",
        method: "get",
        params: {
            departmentId
        }
    })
}

//查询设备
export const getMachine = (workShopId, processId) => {
    return request({
        url: businessUrl + "smallProgram/selectMachineByWorkShopIdAndProcessId",
        method: "get",
        params: {
            workShopId,
            processId
        }
    })
}

//查询班组
export const getGroupList = (workShopId) => {
    return request({
        url: businessUrl + "department/selectGroupName",
        method: "post",
        params: {
            workShopId
        }
    })
}

//查询岗位
export const getPostList = () => {
    return request({
        url: businessUrl + "post/selectAllPost",
        method: "post",
        data: {
        }
    })
}


//查询人员
export const getUserList = (departmentId) => {
    return request({
        url: businessUrl + "corpUsers/selectUserByDept",
        method: "post",
        data: {
            departmentId
        }
    })
}

//获取数据集合
export const getUserMachineDataList = (startOccurTime,endOccurTime,workShopId,machineId,postId,groupId,userId) => {
    return request({
        url: businessUrl + "userMachineRecode/selectListData",
        method: "post",
        data: {
            startOccurTime,
            endOccurTime,
            workShopId,
            machineId,
            postId,
            groupId,
            userId,
        }
    })
}

//获得预估产量
export const getForecastYield = (machineId,strTime,timeStrIotNumber,endTime,timeEndIotNumber,forecastYield) => {
    return request({
        url: businessUrl + "publicMethods/userOverTimeYield",
        method: "post",
        data: {
            machineId,
            strTime,
            timeStrIotNumber,
            timeEndIotNumber,
            endTime,
            forecastYield,
        }
    })
}


//获得iot的米数差值
export const getDifferenceLength = (strTime, machineId, timeStrIotNumber, timeEndIotNumber, endTime) => {
    return request({
        url: businessUrl + "publicMethods/differenceLength",
        method: "post",
        data: {
            machineId,
            strTime,
            timeStrIotNumber,
            timeEndIotNumber,
            endTime,
        }
    })
}

//提交数据
export const commitMachineDateList = (row) => {
    return request({
        url: businessUrl + "userMachineRecode/addListData",
        method: "post",
        data:
        row
    })
}






