<template>
  <div class="yieldManagement" style="height: calc(100% - 320px);">
    <div class="top-heaer">
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-dark d-flex">
            <div class="d-flex">
              <div class="lable">
                <!--<p>开始时间</p>-->
              </div>
              <div>
                <el-date-picker v-model="selectForm.startTime"
                                value-format="yyyy-MM-dd"
                                type="date"
                                :clearable="false"
                                placeholder="选择开始日期" style="width:100%;">
                </el-date-picker>
              </div>
            </div>
            <div class="d-flex" style="margin-left: 10px;">
              <div class="lable">
                <!--<p>结束时间</p>-->
              </div>
              <div>
                <el-date-picker v-model="selectForm.endTime"
                                value-format="yyyy-MM-dd"
                                type="date"
                                :clearable="false"
                                placeholder="选择结束日期" style="width:100%;">
                </el-date-picker>
              </div>
            </div>
            <div class="d-flex" style="margin-left: 10px;">
              <div class="lable">
                <!--<p>车间选择</p>-->
              </div>
              <div>
                <el-select v-model="selectForm.workShopId" @change="getWorkShopId" placeholder="请选择车间">
                  <el-option
                      v-for="item in selectForm.workShopList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="d-flex" style="margin-left: 10px;">
              <div class="lable">
                <!--<p>工序选择</p>-->
              </div>
              <div>
                <el-select v-model="selectForm.processId" @change="getMachineList" placeholder="请选择工序">
                  <el-option
                      v-for="item in selectForm.processList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="d-flex" style="margin-left: 10px;">
              <div class="lable">
                <!--<p>设备选择</p>-->
              </div>
              <div>
                <el-select v-model="selectForm.machineId" clearable placeholder="请选择设备">
                  <el-option
                      v-for="item in selectForm.machineList"
                      :key="item.id"
                      :label="item.code"
                      :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="d-flex" style="margin-left: 10px;">
              <div class="lable">
                <!--<p>岗位选择</p>-->
              </div>
              <div>
                <el-select v-model="selectForm.postId" clearable placeholder="请选择岗位">
                  <el-option
                      v-for="item in selectForm.postList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="d-flex" style="margin-left: 10px;">
              <div class="lable">
                <!--<p>班组选择</p>-->
              </div>
              <div>
                <el-select v-model="selectForm.groupId" @change="getUser" placeholder="请选择班组">
                  <el-option
                      v-for="item in selectForm.groupList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="d-flex" style="margin-left: 10px;">
              <div class="lable">
                <!--<p>人员选择</p>-->
              </div>
              <div>
                <el-select v-model="selectForm.userId" clearable placeholder="请选择人员">
                  <el-option
                      v-for="item in selectForm.userList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.userId">
                  </el-option>
                </el-select>
              </div>
            </div>
            <el-badge>
              <img style="padding-top: 4px; padding-right: 15px; padding-left: 15px; height: 1.7rem; width: 1.7rem;"
                   :title="$t('REFRESH')" @click="batch" src="/img/Bnt/refresh.png"/>
            </el-badge>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="table" style="height: 550px">
      <el-table
          :data="handoverForm.tempList"
          border
          ref="table"
          height="96%"
          style="width: 100%">
        <el-table-column
            prop="workShopName"
            label="车间"
            align="center"
            width="100">
        </el-table-column>
        <el-table-column
            prop="machineCode"
            label="设备编号"
            align="center"
            width="100">
        </el-table-column>
        <el-table-column
            prop="postName"
            label="岗位"
            align="center"
            width="100">
        </el-table-column>
        <el-table-column
            prop="userName"
            label="人员"
            align="center"
            width="">
        </el-table-column>
        <el-table-column
            prop="startOccurTime"
            label="开始时间"
            align="center"
            width="">
        </el-table-column>
        <el-table-column
            prop="endOccurTime"
            label="结束时间"
            align="center"
            width="">
        </el-table-column>
        <el-table-column
            prop="startIotLength"
            label="开始机台米数"
            align="center"
            width="160">
          <template slot-scope="scope">
            <el-input type="number" @change="getTempYield(scope.row)" v-model="scope.row.startIotLength"/>
          </template>
        </el-table-column>
        <el-table-column
            prop="endIotLength"
            label="结束机台米数"
            align="center"
            width="160">
          <template slot-scope="scope">
            <el-input type="number" @change="getTempYield(scope.row)" v-model="scope.row.endIotLength"/>
          </template>
        </el-table-column>
        <el-table-column
            prop="actualLength"
            label="总米数"
            align="center"
            width="120">
        </el-table-column>
        <el-table-column
            prop="yield"
            label="预估产量"
            align="center"
            width="130">
          <template slot-scope="scope">
            <el-input :key="Math.random()" @blur.native.capture="getTempYield(scope.row,scope.$index)"
                      v-model="scope.row.forecastYield"/>
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            align="center"
            width="100">
          <template slot-scope="scope">
            <!--<el-button-->
            <!--@click.native.prevent="deleteRow(scope.$index, list)"-->
            <!--type="text"-->
            <!--size="small">-->
            <!--确认-->
            <!--</el-button>-->
            <el-button
                @click.native.prevent="deleteRow(scope.$index, handoverForm.tempList)"
                type="text"
                size="small">
              移除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-button type="primary" style="float: right;margin-right:68px" @click="subMachineDateList"
                 class="bg7caff5 border-none">提交
      </el-button>
    </div>
  </div>
</template>

<script>
import {
  getWorkShop,
  getProcess,
  getMachine,
  getGroupList,
  getPostList,
  getUserList,
  getForecastYield,
  getDifferenceLength,
  getUserMachineDataList,
  commitMachineDateList,
} from "@/api/monthlyYieldOfUser.js"

export default {
  name: "yieldManagement",
  data() {
    return {
      rules: {
        workIotLength: [
          {required: true, message: '请输入上班机台米数', trigger: 'blur'},
        ],
        afterWorkIotLength: [
          {required: true, message: '请输入下班机台米数', trigger: 'blur'},
        ],
        forecastYield: [
          {required: true, message: '请输入预估产量', trigger: 'blur'},
        ],
        transferUserName: [
          {required: true, message: '请选择交接人员', trigger: 'blur'},
        ],
      },
      selectForm: {
        startTime: '',
        endTime: '',
        workShopList: [],
        processList: [],
        machineList: [],
        postList: [],
        groupList: [],
        userList: [],
        machineId: '',
        workShopId: '',
        processId: '',
        postId: '',
        groupId: '',
        userId: '',
      },
      handoverForm: {
        tempList: [],
        workShopId: '',
        workShopName: '',
        machineCode: '',
        machineId: '',
        postId: '',
        postName: '',
        userId: '',
        userName: '',
        standSpin: '',
        totalSpin: '',
        startCurrentYield: '',
        startCurrentEnergy: '',
        corporationId: '',
        endCurrentEnergy: '',
        endCurrentYield: '',
        endOccurTime: '',
        startOccurTime: '',
        endCumulativeLength: '',
        startCumulativeLength: '',
        startIotLength: '',
        endIotLength: '',
        type: '',
      },


    }
  },
  created() {
    getWorkShop().then(res => {
      this.selectForm.startTime = this.fundate(-11);
      this.selectForm.endTime = this.fundate(0)
      this.selectForm.workShopList = res.data.data;
      this.selectForm.workShopId = this.selectForm.workShopList[0].id;
      getProcess(this.selectForm.workShopId).then(res => {
        let list = res.data.data;
        if (list == undefined || list.length <= 0) {
          this.selectForm.processList = [];
          this.selectForm.machineList = [];
        } else {
          this.selectForm.processList = res.data.data;
          this.selectForm.processId = this.selectForm.processList[0].id;
          getMachine(this.selectForm.workShopId, this.selectForm.processId).then(res => {
            let list2 = res.data.data;
            if (list2 == undefined || list2.length <= 0) {
              this.selectForm.machineList = [];
            } else {
              this.selectForm.machineList = res.data.data;

            }
          })
        }
      });
      getGroupList(this.selectForm.workShopId).then(res => {
        let list4 = res.data.data;
        if (list4 == undefined || list4.length <= 0) {
          this.selectForm.groupList = [];
          this.selectForm.userList = [];
        } else {
          this.selectForm.groupList = res.data.data;
          this.selectForm.groupId = this.selectForm.groupList[0].id;
          getUserList(this.selectForm.groupId).then(res => {
            let list = res.data.data;
            if (list == undefined || list.length <= 0) {
              this.selectForm.userList = [];
            } else {
              this.selectForm.userList = res.data.data;
            }
          })
        }
      });

    });
    getPostList().then(res => {
      let list3 = res.data.data;
      if (list3 == undefined || list3.length <= 0) {
        this.selectForm.postList = [];
      } else {
        this.selectForm.postList = res.data.data;
      }
    })


  },
  methods: {
    fundate(aa) {
      let date1 = new Date();
      let date2 = new Date(date1);
      date2.setDate(date1.getDate() + aa);
      let time2 = date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate();
      return time2
    },
    getWorkShopId(val) {
      this.selectForm.workShopList.find(item => item.id == val)
      getProcess(val).then(res => {
        let list = res.data.data;
        if (list == undefined || list.length <= 0) {
          this.selectForm.processId = '';
          this.selectForm.processList = [];
        } else {
          this.selectForm.processList = res.data.data;
        }
      })
    },
    getMachineList(val) {
      getMachine(this.selectForm.workShopId, val).then(res => {
        let list = res.data.data;
        if (list == undefined || list.length <= 0) {
          this.selectForm.machineId = '';
          this.selectForm.machineList = [];
        } else {
          this.selectForm.machineList = res.data.data;
        }

      })
    },
    getUser(val) {
      getUserList(val).then(res => {
        let list = res.data.data;
        if (list == undefined || list.length <= 0) {
          this.selectForm.userList = [];
        } else {
          this.selectForm.userList = res.data.data;
        }
      })
    },
    batch() {
      getUserMachineDataList(this.selectForm.startTime + " 00:00:00", this.selectForm.endTime + " 00:00:00", this.selectForm.workShopId, this.selectForm.machineId, this.selectForm.postId, this.selectForm.groupId, this.selectForm.userId).then(res => {
        let template = this.list = res.data.data;
        console.log(template)
        this.handoverForm.tempList = [];
        for (let templateKey in template) {
          let obj1 = {
            "id": template[templateKey].id,
            "workShopId": template[templateKey].workShopId,
            "workShopName": template[templateKey].workShopName,
            "machineCode": template[templateKey].machineCode,
            "machineId": template[templateKey].machineId,
            "postId": template[templateKey].postId,
            "postName": template[templateKey].postName,
            "userId": template[templateKey].userId,
            "userName": template[templateKey].userName,
            "standSpin": template[templateKey].standSpin,
            "totalSpin": template[templateKey].totalSpin,
            "startCurrentYield": template[templateKey].startCurrentYield,
            "startCurrentEnergy": template[templateKey].startCurrentEnergy,
            "corporationId": template[templateKey].corporationId,
            "endCurrentEnergy": template[templateKey].endCurrentEnergy,
            "endCurrentYield": template[templateKey].endCurrentYield,
            "endOccurTime": template[templateKey].endOccurTime,
            "startOccurTime": template[templateKey].startOccurTime,
            "endCumulativeLength": template[templateKey].endCumulativeLength,
            "startCumulativeLength": template[templateKey].startCumulativeLength,
            "startIotLength": template[templateKey].startIotLength,
            "endIotLength": template[templateKey].endIotLength,
            "actualLength": template[templateKey].actualLength,
            "forecastYield": template[templateKey].forecastYield,
            "type": template[templateKey].type,
          }
          this.handoverForm.tempList.push(obj1)
        }
      })

    },
    getTempYield(row) {
      getForecastYield(row.machineId, row.startOccurTime, row.startIotLength,
          row.endOccurTime, row.endIotLength, row.forecastYield).then(res => {
        row.forecastYield = res.data.data
        this.$set(row, row.endIotLength, row)
        this.$forceUpdate()
      });
      getDifferenceLength(row.startOccurTime, row.machineId, row.startIotLength,
          row.endIotLength, row.endOccurTime).then(res => {
        row.actualLength = res.data.data
        this.$set(row, row.actualLength, row)
        this.$forceUpdate()
      });

    },
    subMachineDateList() {
      let returnList = []
      this.handoverForm.tempList.forEach(function (item) {
        let temp = {
          "id": item.id,
          "workShopId": item.workShopId,
          "workShopName": item.workShopName,
          "machineCode": item.machineCode,
          "machineId": item.machineId,
          "postId": item.postId,
          "postName": item.postName,
          "userId": item.userId,
          "userName": item.userName,
          "standSpin": item.standSpin,
          "totalSpin": item.totalSpin,
          "startCurrentYield": item.startCurrentYield,
          "startCurrentEnergy": item.startCurrentEnergy,
          "corporationId": item.corporationId,
          "endCurrentEnergy": item.endCurrentEnergy,
          "endCurrentYield": item.endCurrentYield,
          "endOccurTime": item.endOccurTime,
          "startOccurTime": item.startOccurTime,
          "endCumulativeLength": item.endCumulativeLength,
          "startCumulativeLength": item.startCumulativeLength,
          "startIotLength": item.startIotLength,
          "endIotLength": item.endIotLength,
          "actualLength": item.actualLength,
          "forecastYield": item.forecastYield,
          "type": item.type,
        }
        returnList.push(temp)
      });
      commitMachineDateList(returnList).then(res => {
        this.handoverForm.tempList = res.data.data
        if (res.data.data.length > 0) {
          this.$message({
            message: "提交成功," + "有" + res.data.data.length + "数据有误，请查证！",
            type: "success"
          });
        } else {
          this.$message({
            message: "提交成功",
            type: "success"
          });
        }
      })
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },

    standAlone() {
      this.handoverForm.tempList = []
    }

  }
}
</script>

<style scoped lang="scss">
/deep/ .el-card__header {
  color: #FFFFFF;
  background-color: #aaa;
}

.blue {
  background-color: #1f94f4;
}

.yieldManagement {
  padding: 20px;
  background: rgba(245, 246, 248, 1);

  .border-none {
    border: none;
  }

  .bg7caff5 {
    background: #7CAFF5;
  }

  .top-heaer {
    background: #fff;
    padding: 10px;
    border-radius: 5px;
  }

  .lable {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .d-flex {
    display: flex;
  }

  .select-box {
    padding-left: 30px;
    height: 70px;
    border-bottom: 1px solid rgba(151, 151, 151, 1);
    margin-bottom: 30px;

    .btn-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 30px;

      .btn {
        background: none;
        color: rgba(0, 0, 0, .6);
        border: none;
      }

      .btn-ac {
        background: rgba(90, 155, 248, 1);
        color: #fff;
      }
    }
  }

  .table {
    margin-top: 35px;

    /deep/ th {
      background: rgba(224, 226, 228, 1) !important;
      color: #000 !important;
    }
  }
}
</style>
